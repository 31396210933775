<template>
  <div class="container-fluid g-0">
    <div class="row">
      <div class="col-md-12">
        <h4 class="mt-3">Profile</h4>
      </div>
      <div class="col-md-12">
        <profile-form :profile="profile" @save-profile="save($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import ProfileForm from '@/components/ProfileForm.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Profile',
  components: { ProfileForm },
  data: () => ({}),
  computed: {
    ...mapGetters(['user']),
    profile() {
      return this.user.data;
    },
  },
  methods: {
    ...mapActions(['getUserData', 'updateUserData']),
    save(event) {
      this.updateUserData(event);
    },
  },
};
</script>

<style></style>
