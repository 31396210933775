<template>
  <form class="row" @submit.prevent="saveProfile()">
    <div class="row">
      <b class="mb-2">Personal information</b>
    </div>
    <div class="row mb-3">
      <div class="col-md-4">
        <div class="form-floating">
          <input v-model="userProfile.firstName" class="form-control" type="text" placeholder="First name" />
          <label>First name</label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-floating">
          <input v-model="userProfile.lastName" class="form-control" type="text" placeholder="Last name" />
          <label>Last name</label>
        </div>
      </div>
    </div>
    <div class="row" v-if="userProfile.address">
      <div class="col-md-4">
        <div class="form-floating">
          <input v-model="userProfile.address.street" class="form-control" type="text" placeholder="Street" />
          <label>Street</label>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-floating">
          <input v-model="userProfile.address.zip" class="form-control" type="text" placeholder="ZIP" />
          <label>ZIP</label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-floating">
          <input v-model="userProfile.address.city" class="form-control" type="text" placeholder="City" />
          <label>City</label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-floating">
          <select v-model="userProfile.address.country" class="form-control">
            <option disabled value="">Select country</option>
            <option v-for="country in countries" :key="country.code" :value="country.code">
              {{ country.name }}
            </option>
          </select>
          <label>Country</label>
        </div>
      </div>
    </div>
    <div class="row">
      <b class="col-md-12 my-2"> Contact information </b>
      <div class="col-md-4">
        <div class="form-floating">
          <input v-model="userProfile.emailAddress" class="form-control" type="text" placeholder="Email" />
          <label>Email address</label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-floating">
          <input v-model="userProfile.phoneNumber" class="form-control" type="text" placeholder="Phone number" />
          <label>Phone</label>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-floating">
          <input v-model="userProfile.website" class="form-control" type="text" placeholder="Website" />
          <label>Website URL</label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <button type="submit" class="mt-3 btn bg-success text-white" @click.prevent="saveProfile()">
          Save profile
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { countries } from '@/assets/js/countries';

export default {
  name: 'ProfileForm',
  props: ['profile'],
  data() {
    return {
      userProfile: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        website: '',
        address: {
          street: '',
          zip: '',
          city: '',
          country: '',
        },
      },
      countries,
    };
  },
  methods: {
    saveProfile() {
      console.log('saved');
      this.$emit('save-profile', this.userProfile);
    },
  },
  watch: {
    profile: {
      // after state is updated on loading, set userProfile, as well as on mounting/route changing
      handler() {
        this.userProfile = { ...this.profile };
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
